import React from 'react';
import { Link } from 'react-router-dom';
import ListingLocationContacts from '../sidebar/ListingLocationContacts';
import ListingSimilarListings from '../sidebar/ListingSimilarListings';
import ListingTags from '../sidebar/ListingTags';
import ListingWorkingHours from '../sidebar/ListingWorkingHours';
import { MdVerifiedUser,MdCall } from "react-icons/md";
import { FaRegThumbsUp } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from '../../redux/actions/app-actions';
import withRouter from '../../utils/withRouterCustom';
import config from '../../config';
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';
import MyVideoSlider from '../shared/VideoCarousel';
import ReactPlayer from 'react-player';
import RenderReview from '../shared/renderReview';


const imagesSky = [
    {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
    {
        original: "https://picsum.photos/200",
        thumbnail: "https://picsum.photos/200",
    },
    {
        original: "https://picsum.photos/200",
        thumbnail: "https://picsum.photos/200",
    }, {
        original: "https://picsum.photos/200",
        thumbnail: "https://picsum.photos/200",
    }, {
        original: "https://picsum.photos/200",
        thumbnail: "https://picsum.photos/200",
    }, {
        original: "https://picsum.photos/200",
        thumbnail: "https://picsum.photos/200",
    }, {
        original: "https://picsum.photos/200",
        thumbnail: "https://picsum.photos/200",
    }, {
        original: "https://picsum.photos/200",
        thumbnail: "https://picsum.photos/200",
    }, {
        original: "https://picsum.photos/200",
        thumbnail: "https://picsum.photos/200",
    },
];
class ListingDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            itemDetails: null,
            images: [],
            videos: [],
            currentVideoIndex: null,
            reviews: []
        }
    }


    handlePlay = (index) => {
        if (index !== this.state.currentVideoIndex) {
            this.setState({ currentVideoIndex: index });
        }
    };

    handlePause = () => {
        this.setState({ currentVideoIndex: null });
    };

    componentDidMount() {

        const $ = window.$;

        if ($('.thm-owl__carousel').length) {
            $('.thm-owl__carousel').owlCarousel({
                loop: true,
                autoplay: false,
                margin: 30,
                nav: false,
                dots: false,
                smartSpeed: 500,
                autoplayTimeout: 10000,
                navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    992: {
                        items: 2
                    },
                    1200: {
                        items: 2
                    }
                }
            });
        }

        if ($(".progress-levels .progress-box .bar-fill").length) {
            $(".progress-box .bar-fill").each(
                function () {
                    $(".progress-box .bar-fill").appear(function () {
                        var progressWidth = $(this).attr("data-percent");
                        $(this).css("width", progressWidth + "%");
                    });
                }, {
                accY: 0
            }
            );
        }

        if ($(".count-box").length) {
            $(".count-box").appear(
                function () {
                    var $t = $(this),
                        n = $t.find(".count-text").attr("data-stop"),
                        r = parseInt($t.find(".count-text").attr("data-speed"), 10);

                    if (!$t.hasClass("counted")) {
                        $t.addClass("counted");
                        $({
                            countNum: $t.find(".count-text").text(),
                        }).animate({
                            countNum: n,
                        }, {
                            duration: r,
                            easing: "linear",
                            step: function () {
                                $t.find(".count-text").text(
                                    Math.floor(this.countNum)
                                );
                            },
                            complete: function () {
                                $t.find(".count-text").text(this.countNum);
                            },
                        });
                    }
                }, {
                accY: 0
            }
            );
        }



        this.props.getClinicDetails(this.props.router.params.id).then((resp) => {
            if (resp) {

                let images = resp.docs?.flatMap(x => [{ original: config.APIURL1 + '/' + x.url, thumbnail: config.APIURL1 + '/' + x.url }])
                let videos = resp.videos?.flatMap(x => [{ original: config.APIURL1 + '/' + x.url, thumbnail: config.APIURL1 + '/' + x.url }])


                this.setState({ itemDetails: resp || {}, images: images, videos: videos });
                this.props.setDetails(resp);

            }


        }).catch((ex) => {
            console.log({ ex });
        })


        this.props.reviewsClinic(this.props.router.params.id).then((resp) => {
            if (resp) {


                this.setState({ reviews: resp || [] });

            }


        }).catch((ex) => {
            console.log({ ex });
        })

    }




    render() {
        const { itemDetails, images, videos, reviews } = this.state;
        console.log(videos)
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                {/* Start Listings Details Page */}
                {itemDetails &&
                    <section className="listings-details-page pt-5">
                        <div className="container">
                            <div className="row">
                                {/* Start Listings Details Page Content */}
                                <div className="col-12">
                                    {itemDetails &&
                                        <ImageGallery
                                            items={images}
                                            thumbnailPosition={'left'}
                                            showNav={true}
                                            showPlayButton={false}
                                            showFullscreenButton={true}
                                            showBullets={true}
                                            // disableThumbnailSwipe={true}
                                            infinite={true}
                                        />
                                    }
                                    <div className="listings-details-page__content mt-4">                                      

                                        {/* Start SKY Work */}
                                        <div className="listings-details-page__content-profile p-4">
                                            <div className='row'>
                                                <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 text-center'>
                                                    <img alt={itemDetails?.firstName} src={config.backendURL + itemDetails?.image?.url} width={'80%'} height={'auto'} />
                                                </div>
                                                <div className='col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12'>
                                                   <div className='d-flex justify-content-between gap-3 align-items-center mb-3'> 
                                                        <h2>{itemDetails?.firstName} {itemDetails?.lastName}</h2>
                                                        <a href={`tel:${itemDetails.phone}`} className="btn btn-sm btn-info sky-btn my-2"><MdCall /> Call Now</a>
                                                    </div>
                                                    {/* <p>BDS, MDS - Conservative Dentistry & Endodontics STATIC</p> */}
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>{itemDetails?.specialities?.map((x, idx) => <><span>{x.name}</span><span style={{ display: idx >= itemDetails?.specialities.length - 1 ? 'none' : '' }}>,</span></>)}</div>
                                                    <p>{itemDetails?.experience} Years Experience Overall</p>
                                                    <hr />
                                                    <p><MdVerifiedUser /> Medical Registration Verified</p>
                                                    {/* <p><FaRegThumbsUp /> 99% (4321 patients)</p> */}
                                                    <p>{itemDetails?.description}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='sky-videos mt-5'>
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Info</Tab>
                                                    <Tab>Reviews</Tab>
                                                    <Tab>Videos</Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <div className='sky-info-tab'>
                                                        <p className='mb-2'>{itemDetails?.districtDetail?.district_name}, {itemDetails?.gaunpalikaDetail?.gaunpalika_name}</p>

                                                        <p style={{ fontWeight: '700' }}>{itemDetails?.firstName} {itemDetails?.lastName}</p>
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '15px' , marginBottom: '20px' }}>{itemDetails?.specialities?.map((x, idx) =>
                                                            <button type="button" class="btn btn-info sky-btn m-1">{x.name}</button>)}
                                                        </div>

                                                        <hr />
                                                        <div className='row'>
                                                            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 my-2'>
                                                                <Link to={process.env.PUBLIC_URL + `/`}>{itemDetails?.firstName} {itemDetails?.lastName}</Link>
                                                                <ul className="reviews-box">
                                                                    <li><span>4.0</span></li>
                                                                    <li><span className="icon-star"></span></li>
                                                                    <li><span className="icon-star"></span></li>
                                                                    <li><span className="icon-star"></span></li>
                                                                    <li><span className="icon-star"></span></li>
                                                                    <li><span className="icon-star-1"></span></li>
                                                                </ul>
                                                                <p>
                                                                    {itemDetails?.address}                                                            </p>
                                                                {/* <Link to={process.env.PUBLIC_URL + `/`}>Get Directions</Link> */}
                                                            </div>
                                                            {/* <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 my-2'>
                                                            <h5>Mon, Wed - Sun</h5>
                                                            <p>09:00 AM - 06:00 PM</p>
                                                        </div> */}
                                                            {/* <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                                                                <button type="button" class="btn btn-info sky-btn">Book Appointment</button>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className='sky-stories-tab'>
                                                        <div className="comment-one listings-details-page__content-comment">
                                                            <h3 className="comment-one__title">Reviews ({reviews.length || 0})</h3>
                                                            {reviews && reviews.length > 0 && reviews.map(item =>


                                                                <RenderReview
                                                                    review={item}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </TabPanel>




                                                <TabPanel>
                                                    <div className='sky-stories-tab sky-react-videos'>
                                                        <div className="comment-one listings-details-page__content-comment">
                                                            <h3 className="comment-one__title">Videos ({itemDetails?.videos?.length || 0})</h3>


                                                            {/* {videos && videos.length > 0 && videos.map(item =>

                                                                <iframe width="425" height="344"
                                                                    src={item.original}
                                                                    frameborder="0" allowfullscreen></iframe>
                                                            )} */}

                                                            {videos && videos.length > 0 && videos.map((item, index) =>
                                                                <div style={{ width: '30vw', height: '20vh' }} className='player-wrapper'>
                                                                    <ReactPlayer url={item.original} controls={true}
                                                                        className='react-player'
                                                                        playing={index === this.state.currentVideoIndex}
                                                                        onPlay={() => this.handlePlay(index)}
                                                                        onPause={this.handlePause}
                                                                    />
                                                                </div>
                                                            )}

                                                            {videos && videos.length == 0 && <span>No Videos Available</span>}
                                                        </div>
                                                    </div>
                                                </TabPanel>



                                            </Tabs>
                                        </div>
                                        {/* End SKY Work */}

                                        {/* <div className="list-blocks">
                                            <h3 className='mt-5 section-title'>Services</h3>
                                            <div className='d-flex flex-wrap gap-3 align-items-center mt-3'>
                                                {itemDetails && itemDetails?.facilities?.map(item =>

                                                    <div className="list-view-horizontal inner sky-inner" style={{ display: item.isActive ? '' : 'none' }}>

                                                        <img alt={item?.firstName} src={config.backendURL + item?.file?.url} width={'50px'} height={'auto'} />




                                                        <Link to={process.env.PUBLIC_URL + `/`}> {item?.name}</Link>

                                                    </div>

                                                )}
                                            </div>
                                        </div> */}

                                        <div className="listings_three-page one">
                                            <h3 className="mt-5 section-title">Doctors in {itemDetails.firstName} {itemDetails.lastName}</h3>

                                            <div className="row mt-4" >
                                                {
                                                    itemDetails && itemDetails.doctors?.filter(x => x.status).map(item =>
                                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                                            <div className='place-one__single'> 
                                                                <img alt={item.firstName} src={config.backendURL + item?.image?.url} width={'80%'} height={'auto'} />
                                                                <div className='content p-3 w-100'>
                                                                    <div class="d-flex justify-content-between gap-3 align-items-center mb-3">
                                                                    <Link to={'/listings-details-doc/' + item?.id}><h2>{item.firstName} {item.lastName}</h2></Link>
                                                                    <Link to={'/listings-details-doc/' + item?.id} className="btn btn-sm btn-info sky-btn my-2">Book Visit Now</Link>
                                                                    </div>
                                                                    <p>{item.education}</p>
                                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>{item?.specialities?.map((x, idx) => <><span>{x.name}</span><span style={{ display: idx >= item.specialities.length - 1 ? 'none' : '' }}>,</span></>)}</div>
                                                                    <p>{item.experience} Years Experience Overall</p>
                                                                    <hr />
                                                                    <p><MdVerifiedUser /> Medical Registration Verified</p>
                                                                    {/* <p><FaRegThumbsUp /> 99% (4321 patients) </p> */}
                                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                        <ul className="" style={{ display: 'flex', marginTop: '2%', color: 'orange' }}>
                                                                            <li><span>4.0</span></li>
                                                                            <li><span className="icon-star"></span></li>
                                                                            <li><span className="icon-star"></span></li>
                                                                            <li><span className="icon-star"></span></li>
                                                                            <li><span className="icon-star"></span></li>
                                                                            <li><span className="icon-star-1"></span></li>
                                                                        </ul>
                                                                        <span style={{ paddingLeft: '10px', paddingTop: '10px', alignSelf: 'center' }}>₹ {item.consuntantFee} Fee</span>

                                                                    </div>
                                                                    {/* <div class="" style={{ marginTop: '10px' }}><i data-qa-id="time_icon" class="c-card__icon icon-ic_time u-valign--top"></i><span class="u-d-inlineblock u-valign--top"><span class="u-d-inlineblock u-valign--top"><span><span class=""><span data-qa-id="doctor_visit_days" class="u-d-block u-bold"><span><span><span>Mon</span><span>-</span><span>Sat</span></span></span></span>
                                                                    <span style={{ display: 'block' }} class="u-d-block"><span class="u-smallest-font"><span>05:45 PM</span><span>-</span><span>08:30 PM</span></span></span></span></span></span></span></div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                }
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* End Listings Details Page Content */}

                                {/* Start Listings Details Page Sidebar */}
                                {/* <div className="col-xl-4 col-lg-5">
                                <div className="listings-details-page__sidebar">
                                    <ListingWorkingHours
                                        itemDetails={itemDetails}
                                    />

                                </div>
                            </div> */}
                                {/* End Listings Details Page Sidebar */}
                            </div>
                        </div>
                    </section >
                }
                {/* End Listings Details Page */}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        ListingDetails
    )
);

