import React, { useRef } from 'react'
import { Button, Checkbox, Col, DatePicker, Form, Input, Radio, Row } from 'antd';
import moment from 'moment/moment';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
export const Step1Form = (props) => {
    const formRef = useRef();
    const onFinish = (values) => {
        console.log('Success:', values);
        values.dob = dayjs(values.dob).format('YYYY/MM/DD');
        props.onSuccess(values)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const googleLogin = (creds) => {
        console.log({ creds });

        let info = jwtDecode(creds.credential);

        console.log({ info })
        let args = {
            email: info.email,
            firstName: info.given_name,
            lastName: info.family_name,
            googlePicture: info.picture
        }
        props.loginViaGoogle(args);
    };

    return (
        <>
            <Form
            className='row'
                ref={formRef}
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}               

                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >

                <Form.Item
                className='col-lg-6'
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input a valid Email!',
                            type:'email'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                className='col-lg-6'
                    label="First Name"
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your First Name!',
                        },
                    ]}
                    
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Last Name"
                    name="lastName"
                    className='col-lg-6'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Last Name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Phone Number"
                    name="phone"
                    className='col-lg-6'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Phone Number!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Gender!',
                        },
                    ]}
                    className='col-lg-6'
                >
                    <Radio.Group>
                        <Radio value={'Male'}>Male</Radio>
                        <Radio value={'Female'}>Female</Radio>

                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Date of Birth"
                    name="dob"
                    className='col-lg-6'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Date of Birth!',
                        },
                    ]}
                >
                    <DatePicker format={'YYYY/MM/DD'} disabledDate={(current) => {
                        let customDate = moment().format('YYYY/MM/DD');
                        return current && current >= moment(customDate, 'YYYY/MM/DD');
                    }} />
                </Form.Item>

                <Form.Item
                className='col-lg-6'
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                className='col-lg-6'
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Confirm Password!',
                        },

                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item className='col-lg-12'>
                    <Button type="primary" htmlType="submit">
                        Next
                    </Button>
                </Form.Item>

            </Form>
            <div className="separator">OR</div>
            <div className='googleSignup'>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        googleLogin(credentialResponse);
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                />
            </div>

        </>
    )
}
