import React from 'react';
import { Link } from 'react-router-dom';
import ListingLocationContacts from '../sidebar/ListingLocationContacts';
import ListingSimilarListings from '../sidebar/ListingSimilarListings';
import ListingTags from '../sidebar/ListingTags';
import ListingWorkingHours from '../sidebar/ListingWorkingHours';
import { MdVerifiedUser } from "react-icons/md";
import { FaRegThumbsUp } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from '../../redux/actions/app-actions';
import withRouter from '../../utils/withRouterCustom';
import config from '../../config';
import RenderReview from '../shared/renderReview';
import { notifyUser } from '../../utils/notification-service';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaPhoneAlt  } from "react-icons/fa";


class ListingDetails extends React.Component {

    constructor(props) {
        super(props);
        this.countRef = React.createRef();
        this.state = {
            itemDetails: {},
            availabilityDetails: null,
            doctorAppointments: null,
            docReviews: []
        }
        this.countRef.current = 0;
    }
    async componentDidMount() {
    
        console.log('ddididididi')
        const $ = window.$;

        if ($('.thm-owl__carousel').length) {
            $('.thm-owl__carousel').owlCarousel({
                loop: true,
                autoplay: false,
                margin: 30,
                nav: false,
                dots: false,
                smartSpeed: 500,
                autoplayTimeout: 10000,
                navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    992: {
                        items: 2
                    },
                    1200: {
                        items: 2
                    }
                }
            });
        }

        if ($(".progress-levels .progress-box .bar-fill").length) {
            $(".progress-box .bar-fill").each(
                function () {
                    $(".progress-box .bar-fill").appear(function () {
                        var progressWidth = $(this).attr("data-percent");
                        $(this).css("width", progressWidth + "%");
                    });
                }, {
                accY: 0
            }
            );
        }

        if ($(".count-box").length) {
            $(".count-box").appear(
                function () {
                    var $t = $(this),
                        n = $t.find(".count-text").attr("data-stop"),
                        r = parseInt($t.find(".count-text").attr("data-speed"), 10);

                    if (!$t.hasClass("counted")) {
                        $t.addClass("counted");
                        $({
                            countNum: $t.find(".count-text").text(),
                        }).animate({
                            countNum: n,
                        }, {
                            duration: r,
                            easing: "linear",
                            step: function () {
                                $t.find(".count-text").text(
                                    Math.floor(this.countNum)
                                );
                            },
                            complete: function () {
                                $t.find(".count-text").text(this.countNum);
                            },
                        });
                    }
                }, {
                accY: 0
            }
            );
        }

        this.countRef.current += 1;
        if (this.countRef.current == 1) {
         
            await this.props.getDocDetails(this.props.router.params.id).then((resp) => {

                this.setState({ itemDetails: resp });
                this.props.setDetails(resp);

            }).catch((ex) => {
                console.log({ ex });
            })

            await this.props.getAvailabilityDetails(this.props.router.params.id).then((resp) => {

                if (resp) {
                    this.setState({ availabilityDetails: resp.availableSlots, doctorAppointments: resp.doctorAppointments });

                }


            }).catch((err) => {
                notifyUser(err.message || 'Something went wrong', 'error', 5);
                console.log({ err });
            })

            await this.props.getDocReviews(this.props.router.params.id).then((resp) => {
              
                this.setState({ docReviews: resp });


            }).catch((ex) => {
                console.log({ ex });
            })
        }

    }

    StarList = ({ count }) => {
        const stars = Array.from({ length: count }, (_, index) => (
            <li key={index}><span className="icon-star"></span></li>
        ));

        return (
            <>
                {stars}
            </>
        );
    }


    render() {
        let { itemDetails, availabilityDetails, doctorAppointments, docReviews } = this.state;

        // itemDetails = {
        //     "id": 2,
        //     "passwordHash": "$2a$10$.yb5HiAAz26dGanRliZ7XOc1tAHY9XUl8S0MQxHfQx22zFTIXaPnO",
        //     "firstName": "Max",
        //     "lastName": "Clinic",
        //     "userName": "max_clinic",
        //     "clinic_id": null,
        //     "gender": null,
        //     "role_id": 5,
        //     "email": "info@max.com",
        //     "consuntantFee": null,
        //     "education": null,
        //     "experience": 6,
        //     "description": "Max Clinic is a chain of full service Cosmetic Dermatology clinics, providing a wide range of services to improve the health of your skin and hair. Our team of experienced dermatologists are committed to providing each of our clients with customised care and result oriented treatment options.",
        //     "status": true,
        //     "address": "18, 1st Main, Koramangala 1st Block, Jakkasandra Extension,Near Wipro Park & Opposite to Sahana Child Care",
        //     "pincode": "334455",
        //     "phone": "4455444433",
        //     "country": null,
        //     "state": 5,
        //     "district": 52,
        //     "city": 1534,
        //     "isVerified": null,
        //     "government_id": "TRTRYYTT4545",
        //     "user_type": 2,
        //     "avatar": "K9gHNJApUjJCCUSq",
        //     "dob": null,
        //     "createdAt": "2023-12-21T11:08:42.000Z",
        //     "updatedAt": "2023-12-29T08:25:12.000Z",
        //     "type_id": null,
        //     "doctors": [
        //         {
        //             "id": 3,
        //             "passwordHash": "$2a$10$ckhsQCPIOWNcBi9AWdYsb.nsoymdXT9sAV5F8Iun8kQzus48Vk7dO",
        //             "firstName": "Pradeep",
        //             "lastName": "Saini",
        //             "userName": "pradeep_s",
        //             "clinic_id": 2,
        //             "gender": null,
        //             "role_id": 2,
        //             "email": "pradeep.saini@g.com",
        //             "consuntantFee": 566,
        //             "education": "BDS, MDS - Conservative Dentistry & Endodontics",
        //             "experience": 5,
        //             "description": "Dr. Pradeep Saini having completed her graduation (BDS) in the esteemed JSS Dental College and Research Center, Mysore in the year 2004.",
        //             "status": true,
        //             "address": "ted 11",
        //             "pincode": "445544",
        //             "phone": "5522336655",
        //             "country": null,
        //             "state": 5,
        //             "district": 52,
        //             "city": 1534,
        //             "isVerified": null,
        //             "government_id": "BGTTYY443",
        //             "user_type": 2,
        //             "avatar": "S2vMu5ww2wKG2xJx",
        //             "dob": "2023-12-26T11:11:01.000Z",
        //             "createdAt": "2023-12-21T11:12:29.000Z",
        //             "updatedAt": "2023-12-29T07:51:03.000Z",
        //             "type_id": null,
        //             "stateDetail": {
        //                 "id": 3,
        //                 "name": "Bagmati Pradesh",
        //                 "nepali_name": "वाग्मती प्रदेश",
        //                 "isActive": null,
        //                 "createdAt": "2023-12-24T08:58:01.000Z",
        //                 "updatedAt": "2023-12-24T08:58:01.000Z"
        //             },
        //             "districtDetail": {
        //                 "id": 3,
        //                 "district_name": "ILLAM",
        //                 "district_name_nepali": "इलाम",
        //                 "imu_district": "ILAM",
        //                 "state_id": 1,
        //                 "isActive": null,
        //                 "createdAt": "2023-12-24T08:57:53.000Z",
        //                 "updatedAt": "2023-12-24T08:57:53.000Z"
        //             },
        //             "gaunpalikaDetail": {
        //                 "id": 1534,
        //                 "gaunpalika_name": "SISNE RURAL MUNICIPALITY",
        //                 "gaunpalika_name_nepali": "सिस्ने",
        //                 "imu_gaunpalika_name": "SISNE",
        //                 "state_id": 5,
        //                 "district_id": 52,
        //                 "isActive": null,
        //                 "createdAt": "2023-12-24T08:58:07.000Z",
        //                 "updatedAt": "2023-12-24T08:58:07.000Z"
        //             },
        //             "specialities": [
        //                 {
        //                     "id": 4,
        //                     "name": "Pediatrician",
        //                     "imageURL": "uploads/logos/Pediatrician.png",
        //                     "SpecialitiesUser": {
        //                         "createdAt": "2023-12-29T07:51:03.000Z",
        //                         "updatedAt": "2023-12-29T07:51:03.000Z",
        //                         "UserId": 3,
        //                         "SpecialityId": 4
        //                     }
        //                 },
        //                 {
        //                     "id": 6,
        //                     "name": "Gastroenterologist",
        //                     "imageURL": "uploads/logos/Gastroenterologist.png",
        //                     "SpecialitiesUser": {
        //                         "createdAt": "2023-12-29T07:51:03.000Z",
        //                         "updatedAt": "2023-12-29T07:51:03.000Z",
        //                         "UserId": 3,
        //                         "SpecialityId": 6
        //                     }
        //                 },
        //                 {
        //                     "id": 8,
        //                     "name": "Cardiologist",
        //                     "imageURL": "uploads/logos/Cardiologist.png",
        //                     "SpecialitiesUser": {
        //                         "createdAt": "2023-12-29T07:51:03.000Z",
        //                         "updatedAt": "2023-12-29T07:51:03.000Z",
        //                         "UserId": 3,
        //                         "SpecialityId": 8
        //                     }
        //                 }
        //             ],
        //             "image": {
        //                 "file_id": "S2vMu5ww2wKG2xJx",
        //                 "name": "Screenshot 2023-12-21 at 16.42.11.png",
        //                 "url": "https://imagesx.practo.com/providers/dr-rashmi-shetty-endodontist-bangalore-9975a1fc-248b-4879-83cb-e57fa5f171ac.jpg",
        //                 "createdAt": "2023-12-28T10:07:54.000Z",
        //                 "updatedAt": "2023-12-28T10:07:54.000Z"
        //             }
        //         }
        //     ],
        //     "specialities": [
        //         {
        //             "id": 1,
        //             "name": "General practitioner",
        //             "imageURL": "uploads/logos/practioner.png",
        //             "SpecialitiesUser": {
        //                 "createdAt": "2023-12-29T08:25:12.000Z",
        //                 "updatedAt": "2023-12-29T08:25:12.000Z",
        //                 "UserId": 2,
        //                 "SpecialityId": 1
        //             }
        //         },
        //         {
        //             "id": 8,
        //             "name": "Cardiologist",
        //             "imageURL": "uploads/logos/Cardiologist.png",
        //             "SpecialitiesUser": {
        //                 "createdAt": "2023-12-29T08:25:12.000Z",
        //                 "updatedAt": "2023-12-29T08:25:12.000Z",
        //                 "UserId": 2,
        //                 "SpecialityId": 8
        //             }
        //         }
        //     ],
        //     "facilities": [
        //         {
        //             "id": 4,
        //             "name": "Free Parking",
        //             "isActive": true,
        //             "file_id": "2hJFAZOcxh94uzS9",
        //             "createdAt": "2023-12-28T14:18:52.000Z",
        //             "updatedAt": "2023-12-28T14:18:52.000Z",
        //             "FacilitiesUser": {
        //                 "createdAt": "2023-12-29T08:25:12.000Z",
        //                 "updatedAt": "2023-12-29T08:25:12.000Z",
        //                 "UserId": 2,
        //                 "FacilityId": 4
        //             },
        //             "file": {
        //                 "file_id": "2hJFAZOcxh94uzS9",
        //                 "name": "istockphoto-1150768232-612x612.jpg",
        //                 "url": "uploads/1703773127500.jpg",
        //                 "createdAt": "2023-12-28T14:18:47.000Z",
        //                 "updatedAt": "2023-12-28T14:18:47.000Z"
        //             }
        //         },
        //         {
        //             "id": 5,
        //             "name": "Pet Friendly",
        //             "isActive": true,
        //             "file_id": "tY7vEKDZAWYCHA8C",
        //             "createdAt": "2023-12-28T14:19:51.000Z",
        //             "updatedAt": "2023-12-28T14:19:51.000Z",
        //             "FacilitiesUser": {
        //                 "createdAt": "2023-12-29T08:25:12.000Z",
        //                 "updatedAt": "2023-12-29T08:25:12.000Z",
        //                 "UserId": 2,
        //                 "FacilityId": 5
        //             },
        //             "file": {
        //                 "file_id": "tY7vEKDZAWYCHA8C",
        //                 "name": "Pet Friendly.jpg",
        //                 "url": "uploads/1703773189594.jpg",
        //                 "createdAt": "2023-12-28T14:19:49.000Z",
        //                 "updatedAt": "2023-12-28T14:19:49.000Z"
        //             }
        //         }
        //     ],
        //     "stateDetail": {
        //         "id": 2,
        //         "name": "Madhesh Pradesh",
        //         "nepali_name": "प्रदेश नं. २",
        //         "isActive": null,
        //         "createdAt": "2023-12-24T08:58:01.000Z",
        //         "updatedAt": "2023-12-24T08:58:01.000Z"
        //     },
        //     "districtDetail": {
        //         "id": 2,
        //         "district_name": "PANCHTHAR",
        //         "district_name_nepali": "पांचथर",
        //         "imu_district": "PANCHTHAR",
        //         "state_id": 1,
        //         "isActive": null,
        //         "createdAt": "2023-12-24T08:57:53.000Z",
        //         "updatedAt": "2023-12-24T08:57:53.000Z"
        //     },
        //     "gaunpalikaDetail": {
        //         "id": 1534,
        //         "gaunpalika_name": "SISNE RURAL MUNICIPALITY",
        //         "gaunpalika_name_nepali": "सिस्ने",
        //         "imu_gaunpalika_name": "SISNE",
        //         "state_id": 5,
        //         "district_id": 52,
        //         "isActive": null,
        //         "createdAt": "2023-12-24T08:58:07.000Z",
        //         "updatedAt": "2023-12-24T08:58:07.000Z"
        //     },
        //     "image": {
        //         "file_id": "K9gHNJApUjJCCUSq",
        //         "name": "clinic-logo-2.png",
        //         "url": "https://imagesx.practo.com/providers/dr-rashmi-shetty-endodontist-bangalore-9975a1fc-248b-4879-83cb-e57fa5f171ac.jpg",
        //         "createdAt": "2023-12-28T14:48:00.000Z",
        //         "updatedAt": "2023-12-28T14:48:00.000Z"
        //     }
        // }


        console.log('this.countRef.current',this.countRef.current);
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                {/* Start Listings Details Page */}
                <section className="listings-details-page">
                    <div className="container">
                        <div className="row">
                            {/* Start Listings Details Page Content */}
                            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                <div className="listings-details-page__content">
                                    {/* <div className="listings-details-page__content-img1">
                                        <img src={publicUrl+"assets/images/resources/listings_details-img1.jpg"} alt="#" />
                                    </div>

                                    <div className="listings-details-page__content-text-box1">
                                        <h3>Description</h3>
                                        <p className="text1">It is a long established fact that a reader will be distracted by the
                                            readable content of a page when looking at its layout. The point of using Lorem
                                            Ipsum is that it has a more-or-less normal distribution of letters, as opposed to
                                            using 'Content here, content here', making it look like readable English.</p>

                                        <p className="text2">Many desktop publishing packages and web page editors now use Lorem
                                            Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many
                                            web sites still in their infancy. Various versions have evolved over the years,
                                            sometimes by accident, sometimes on purpose (injected humour and the like).</p>

                                        <div className="btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn">Visit Website</Link>
                                        </div>
                                    </div> */}

                                    {/* Start SKY Work */}
                                    <div className="listings-details-page__content-profile p-4 mb-5">
                                        <div className='doc_details d-flex flex-md-nowrap flex-wrap gap-4'>
                                            <img alt={itemDetails.firstName} src={config.backendURL + itemDetails?.image?.url} className='profile-image' />
                                            <div className='content-details'>
                                                <div className="d-flex flex-wrap justify-content-between gap-3 align-items-center mb-3">
                                                    <h2>Dr. {itemDetails.firstName} {itemDetails.lastName}</h2>
                                                    {/* <Link className="btn btn-info sky-btn d-flex justify-content-center align-items-center gap-2"> <FaPhoneAlt /> Call Now</Link> */}
                                                </div>
                                                
                                                <p>{itemDetails.education} </p>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>{itemDetails?.specialities?.map((x, idx) => <><span>{x.name}</span><span style={{ display: idx >= itemDetails.specialities.length - 1 ? 'none' : '' }}>,</span></>)}</div>
                                                <p>{itemDetails.experience} Years Experience Overall</p>
                                                <hr />
                                                <p><MdVerifiedUser /> Medical Registration Verified</p>
                                                {/* <p><FaRegThumbsUp /> 99% (4321 patients)</p> */}
                                                <p>{itemDetails.description}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='sky-videos'>
                                        <Tabs>
                                            <TabList>
                                                <Tab>Info</Tab>
                                                {/* <Tab>Stories</Tab> */}
                                            </TabList>

                                            <TabPanel>
                                                <div className='sky-info-tab'>
                                                    <p className='mb-2'>{itemDetails?.clinic?.districtDetail?.district_name}, {itemDetails?.clinic?.gaunpalikaDetail?.gaunpalika_name}</p>

                                                    <Link to={'/listings-details-clinic/' + itemDetails?.clinic?.id}><p style={{ fontWeight: '700' }}>{itemDetails?.clinic?.firstName} {itemDetails?.clinic?.lastName}</p></Link>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '3%' }}>{itemDetails?.clinic?.specialities?.map((x, idx) =>
                                                        <button type="button" class="btn btn-info sky-btn m-1">{x.name}</button>)}
                                                    </div>

                                                    <hr />
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <Link to={process.env.PUBLIC_URL + `/`}>{itemDetails?.clinic?.firstName} {itemDetails?.clinic?.lastName}</Link>
                                                            <ul className="reviews-box">
                                                                <li><span style={{ marginRight: '10px' }}>{Math.floor(itemDetails.averageRating)}.0</span><span style={{ display: itemDetails.averageRating == 0 ? '' : 'none' }}>Rating</span></li>

                                                                {<this.StarList count={itemDetails.averageRating} />}

                                                            </ul>
                                                            <p>
                                                                {itemDetails?.clinic?.address}                                                            </p>
                                                        </div>
                                                        {/* <div className='col'>
                                                            <h5>Mon, Wed - Sun</h5>
                                                            <p>09:00 AM - 06:00 PM</p>
                                                        </div>
                                                        <div className='col'>
                                                            <p>₹{itemDetails.consuntantFee}</p>
                                                            <button type="button" class="btn btn-info sky-btn">Book Appointment</button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className='sky-stories-tab'>
                                                    <div className="comment-one listings-details-page__content-comment">
                                                        <h3 className="comment-one__title">Stories ({(docReviews || [].length)})</h3>
                                                        {docReviews && (docReviews || []).map(rev =>

                                                            <RenderReview
                                                                review={rev}
                                                            />
                                                        )}

                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                    {/* End SKY Work */}





                                    <div className="comment-one listings-details-page__content-comment">
                                        <h3 className="comment-one__title">Reviews ({(docReviews || []).length})</h3>
                                        {docReviews && (docReviews || []).map(rev =>

                                            <RenderReview
                                                review={rev}
                                            />
                                        )}
                                        {(docReviews || []).length == 0 && <span>No Reviews</span>}
                                    </div>


                                </div>
                            </div>
                            {/* End Listings Details Page Content */}

                            {/* Start Listings Details Page Sidebar */}
                            <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                <div className="listings-details-page__sidebar">
                                    {doctorAppointments && <ListingWorkingHours
                                        itemDetails={itemDetails}
                                        availabilityDetails={availabilityDetails}
                                        doctorAppointments={doctorAppointments}
                                    />
    }
                                    {/* <ListingLocationContacts />
                                    <ListingSimilarListings />
                                    <ListingTags /> */}
                                </div>
                            </div>
                            {/* End Listings Details Page Sidebar */}
                        </div>
                    </div>
                </section>
                {/* End Listings Details Page */}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        ListingDetails
    )
);

