import React from 'react';
import { FaRegThumbsUp } from 'react-icons/fa';
import { MdVerifiedUser } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import withRouter from '../../utils/withRouterCustom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from '../../redux/actions/app-actions';
import HeaderSearch from '../../common/header-search';
import config from '../../config';
import { Select, Radio, Button } from 'antd';

// import type { RadioChangeEvent } from 'antd';
class ListingOne extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searching: false,
            gaunPalikas: [],
            doctors: [],
            categories: [],
            selectedGaunpalika: null,
            loading: true,
            listingDoctorsClinics: [],
            doctorsList: [],
            clinicsList: [],
            filters: {},
            value: 1
        }
    }
    async componentDidMount() {

        const $ = window.$;

        if ($(".tabs-box").length) {
            $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
                e.preventDefault();
                var target = $($(this).attr("data-tab"));

                if ($(target).is(":visible")) {
                    return false;
                } else {
                    target
                        .parents(".tabs-box")
                        .find(".tab-buttons")
                        .find(".tab-btn")
                        .removeClass("active-btn");
                    $(this).addClass("active-btn");
                    target
                        .parents(".tabs-box")
                        .find(".tabs-content")
                        .find(".tab")
                        .fadeOut(0);
                    target
                        .parents(".tabs-box")
                        .find(".tabs-content")
                        .find(".tab")
                        .removeClass("active-tab");
                    $(target).fadeIn(300);
                    $(target).addClass("active-tab");
                }
            });
        }

        $('select').niceSelect();


        this.componentInitializerEssentials();
    }


    componentInitializerEssentials = async () => {


        const { router } = this.props;
        console.log({ router })
        let params = router?.location?.search;
        let selectedString = new URLSearchParams(params).get("selectedString"); // this is mainly id-- string only in case of home to list via specilities
        let cityId = new URLSearchParams(params).get("cityId")
        let selectedStringId = new URLSearchParams(params).get("selectedStringId");
        let stateId = new URLSearchParams(params).get("stateId")


        // this.setState({
        //     selectedGaunpalika: config.defaultCityName, selectedString: undefined,
        //     gaunPalikas: (router?.location?.state?.gaunPalikas) || [], doctors: (router?.location?.state?.doctors) || [],
        //     categories: (router?.location?.state?.categories) || []
        // })



        /**
         * selected string here is actually the selected category ID
         * 
         */

        // this if is for search from home, both dropdowns
        let _filters = {};
        let _selectedString = null;
        let _selectedGaunpalika = null;
        let _categories = [];
        let _gaunPalikas = [];



        if (selectedString && cityId) {

            _filters.city = Number(cityId);
            _selectedGaunpalika = Number(cityId);
            _selectedString = Number(selectedString);
            _gaunPalikas = (router?.location?.state?.gaunPalikas) || [];
            _categories = (router?.location?.state?.categories) || []

        }

        //// this is the Menu click listing case
        else if (!selectedString && !cityId && !stateId) {
            _filters.city = config.defaultCityId;
            _selectedGaunpalika = config.defaultCityId;
            _gaunPalikas = [{ id: config.defaultCityId, gaunpalika_name: config.defaultCityName }];
        }

        // coming from home page categories slider
        else if (!cityId && selectedString && !stateId) {

            _filters.city = config.defaultCityId;
            _selectedGaunpalika = config.defaultCityId;

            _selectedString = Number(selectedStringId);

            _gaunPalikas = [{ id: config.defaultCityId, gaunpalika_name: config.defaultCityName }];

            await this.props.getSpecialities().then((resp) => {

                if (resp) {
                    _categories = resp;
                }
            })
                .catch((ex) => {
                    console.log({ ex })
                })

        }

        /// coming from the map
        else if (stateId) {
            _filters.state = Number(stateId);

        }


        this.setState({ filters: _filters, selectedString: _selectedString, selectedGaunpalika: _selectedGaunpalika, categories: _categories, gaunPalikas: _gaunPalikas, loading: false }, () => {


            this.fetchResults();

        })
    }



    fetchResults = () => {


        let { selectedGaunpalika, selectedString, filters } = this.state;

        let _filters = this.deleteEmptyObjects(filters);

        this.props.getDoctorsBySpecialityId(selectedString, _filters).then((resp) => {
            console.log({ resp })
            if (resp && !resp.error) {
                this.setState({ listingDoctorsClinics: resp, doctorsList: resp.filter(x => x.role_id == 2), clinicsList: resp.filter(x => x.role_id == 5) });
            }

        }).catch((ex) => {
            console.log({ ex });
        })
    }


    deleteEmptyObjects = (myObject) => {

        for (const key in myObject) {
            if (myObject[key] === null || myObject[key] === undefined) {
                delete myObject[key];
            }
        }
        return myObject;
    }
    componentDidUpdate(prevProps) {

        if (prevProps.router !== this.props.router) {

            console.log('jkjkjkjkkjakjkdjakljdkwahfajkwhfjkesghfjksgfhjgsefhjgsefhjgshjvbsehjfvhjsef');
            this.setState({ loading: true }, () => {
                this.componentInitializerEssentials();

            })

        }
    }

    onChange = (e) => {
        console.log('radio checked', e.target.value);
        this.setState({ value: e.target.value });
    };

    onUserInput = (val) => {

        if (val.length > 0) {
            this.setState({ searching: true })
            this.props.getGaunpalikas(val).then((resp) => {

                console.log({ resp })
                this.setState(
                    {
                        gaunPalikas: resp
                    }, () => {
                        this.setState({ searching: false })
                    })

            }).catch((ex) => {
                console.log({ ex });
            })
        }
        else if (val == "" || !val) {

            this.setState({ gaunPalikas: [] })
        }
    }

    onUserSelect = (e) => {
        let { filters } = this.state;
        if (filters.state) delete filters['state'];
        filters.city = e.value;
        this.setState({ loading: true }, () => {

            this.setState({ selectedGaunpalika: e.value, selectedString: undefined, filters: filters }, () => {
                this.setState({ loading: false });
            })

        })

    }

    onUserStringSelect = (e) => {
        console.log({ e })
        this.setState({ selectedString: e.value }, () => {

            if (e.title == 'Doctor') {
                // redirect to doctor detail
                this.props.router.navigate('/listings-details-doc/' + e.value);
            }
            else if (e.title == 'Clinic') {
                // redirect to doctor detail
                this.props.router.navigate('/listings-details-clinic/' + e.value);
            }

            else {
                this.fetchResults();
            }


        })
    }

    // this is the dropdown caller
    onUserStringInput = async (val) => {

        if (val.length > 0) {
            this.setState({ searching: true })

            await this.props.getSpecialities(val).then((resp) => {

                if (resp) {
                    this.setState({ categories: resp });
                }
            })
                .catch((ex) => {
                    console.log({ ex })
                })


            this.props.getDoctorsClinicsByGaunPalika(this.state.selectedGaunpalika, val).then((resp) => {

                console.log({ resp })
                this.setState(
                    {
                        doctors: resp
                    }, () => {
                        this.setState({ searching: false })
                    })

            }).catch((ex) => {
                console.log({ ex });
            })
        }
        else if (val == "" || !val) {

            this.setState({ doctors: [] })
        }
    }






    filterHandler = (val, type) => {
        console.log('aaaaaa', val, type)
        const { filters } = this.state;

        if (val == undefined) {
            delete filters[type];

        }

        if (type == 'gender') {

            filters.gender = val;

        }

        else if (type == 'experience') {
            filters.experience = val;

        }

        this.setState({ filters: filters }, () => {
            this.fetchResults();
        })

        console.log({ filters });
    }

    StarList = ({ count }) => {
        const stars = Array.from({ length: count }, (_, index) => (
            <li key={index}><span className="icon-star"></span></li>
        ));

        return (
            <>
                {stars}
            </>
        );
    }

    render() {
        let { gaunPalikas, searching, selectedGaunpalika, doctors, categories, loading, listingDoctorsClinics, doctorsList,
            clinicsList, listingDoctorsClinicsOriginal, filters, selectedString } = this.state;
        console.log({ selectedString });
        console.log({ selectedGaunpalika });

        let html = [];

        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                const value = filters[key];
                html.push((`${key}: ${value}`))
            }
        }


        console.log({ doctorsList });
        let publicUrl = process.env.PUBLIC_URL + '/';
        console.log({ html })
        return (
            <>

                <section className="listings_one_wrap overflow-visible">
                    <div className="container-full-width clearfix">
                        <div className="listings__one__content container pt-0 px-md-2 bg-transparent">
                            <div className="listings_one_content_left search-doc-clinic">
                                <div className="row">
                                    <div className="col-xl-12">
                                        {/* <form className="listings_one_content_left_form sky_listings_one_content"> */}
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="banner-one__tab-content-places-form">
                                                    {!loading &&
                                                        <HeaderSearch
                                                            onUserInput={this.onUserInput}
                                                            onUserStringInput={this.onUserStringInput}
                                                            onUserStringSelect={this.onUserStringSelect}
                                                            onUserSelect={this.onUserSelect}
                                                            categories={categories}
                                                            doctors={doctors}
                                                            gaunPalikas={gaunPalikas}
                                                            searching={searching}
                                                            selectedString={this.state.selectedString}
                                                            selectedGaunpalika={selectedGaunpalika}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input_box">
                                                    <div className="select-box">
                                                        <Select placeholder="Select Gender" className="sky-dropdown selectmenu wide" onChange={(e) => this.filterHandler(e, 'gender')} style={{ width: '100%' }} allowClear>
                                                            <option selected="selected" disabled value={'default'}>Select Gender</option>
                                                            <option value={'Male'} key={1}>Male Doctor</option>
                                                            <option value={'Female'} key={2}>Female Doctor</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input_box">
                                                    <div className="select-box">
                                                        <Select placeholder="Experiance" className="sky-dropdown selectmenu wide" onChange={(e) => this.filterHandler(e, 'experience')} style={{ width: '100%' }} allowClear>
                                                            <option selected="selected" disabled>Experiance</option>
                                                            <option value={5}>5+ Years of experiance</option>
                                                            <option value={10}>10+ Years of experiance</option>
                                                            <option value={15}>15+ Years of experiance</option>
                                                            <option value={20}>20+ Years of experiance</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </form> */}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="filter">
                                            <div className="filter_inner_content">
                                                <div className="left">
                                                    <div className="left_text">
                                                        <h4>Over {listingDoctorsClinics.length} items found</h4>
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {clinicsList && clinicsList.length > 0 && <section className="listings_three-page clinics-section one">
                                <h3 className='mt-5 section-title'>Clinics</h3>
                                <div className="row mt-4">
                                    {/* Start Place One Single Clinics */}
                                    {clinicsList && clinicsList.filter(x => x.status).map(item =>

                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="place-one__single">
                                                <img alt={item.firstName} src={config.backendURL + item?.image?.url} />
                                                <div className='content p-3 w-100'>
                                                    <div className='d-flex justify-content-between gap-3 align-items-center mb-3'> 
                                                        <h2>{item.firstName} {item.lastName}</h2> 
                                                        <Link to={`${process.env.PUBLIC_URL}/listings-details-clinic/` + item.id} className="btn btn-sm btn-info sky-btn my-1">Book Clinic Visit</Link>
                                                    </div>
                                                    {/* <p>BDS, MDS - Conservative Dentistry & Endodontics</p> */}
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>{item.specialities.map((x, idx) => <><span>{x.name}</span><span style={{ display: idx >= item.specialities.length - 1 ? 'none' : '' }}>,</span></>)}</div>
                                                    <p>{item.experience} Years Experience Overall</p>
                                                    <hr />
                                                    <p><MdVerifiedUser /> Medical Registration Verified</p>
                                                    {/* <p><FaRegThumbsUp /> 99% (4321 patients)</p> */}
                                                    {/* <p>Dr. Rashmi Shetty having completed her graduation (BDS) in the esteemed JSS Dental College and Research Center, Mysore in the year 2004. </p> */}
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    )}

                                    {/* End Place One Single Doctors*/}


                                </div>
                            </section>
                            }
                            

                            {doctorsList && doctorsList.length > 0 && <section className="listings_three-page one mt-5">
                                <div className='subtitle d-none'>Quality Stuff</div>
                                <h3 className='section-title'>Doctors</h3>
                                <div className="row mt-4">
                                    {/* Start Place One Single Doctors */}
                                    {doctorsList && doctorsList.filter(x => x.status).map(item =>

                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="place-one__single">
                                                <img alt="Dr. Rashmi Shetty Endodontist in Bangalore" src={config.backendURL + item?.image?.url}/>
                                                <div className='content p-3 w-100'>
                                                    <div className='d-flex justify-content-between gap-3 align-items-center mb-3'>
                                                        <h2>Dr. {item.firstName} {item.lastName}</h2>
                                                        <Link to={`${process.env.PUBLIC_URL}/listings-details-doc/${item.id}`} className="btn btn-sm btn-info sky-btn my-1">Book Clinic Visit</Link>
                                                    </div>
                                                    {/* <p>BDS, MDS - Conservative Dentistry & Endodontics</p> */}
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>{item.specialities.map((x, idx) => <><span>{x.name}</span><span style={{ display: idx >= item.specialities.length - 1 ? 'none' : '' }}>,</span></>)}</div>
                                                    <p>{item.experience} Years Experience Overall</p>
                                                    <hr />
                                                    <p><MdVerifiedUser /> Medical Registration Verified</p>
                                                    <p><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <FaRegThumbsUp style={{ marginRight: '10px' }} /><span style={{ marginRight: '10px' }}>{Math.floor(item.averageRating)}.0</span> {<this.StarList count={item.averageRating} />} <span style={{ display: item.averageRating == 0 ? '' : 'none' }}>Rating</span></div></p>
                                                    {/* <p>Dr. Rashmi Shetty having completed her graduation (BDS) in the esteemed JSS Dental College and Research Center, Mysore in the year 2004. </p> */}                                                   
                                                </div>  
                                            </div>
                                        </div>

                                    )}

                                    {/* End Place One Single Doctors*/}


                                </div>
                            </section>
                            }

                    

                            {/* <Button className='sky-btn' type="link">Load More+</Button> */}


                        </div>
                        {/* <div className="listings__one__map">
                            <iframe
                                title="Map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd"
                                className="google-map" allowfullscreen>
                            </iframe>
                        </div> */}
                    </div>
                </section>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        ListingOne
    )
);

