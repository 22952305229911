import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import HeaderOne from '../../common/header/HeaderOne';
import FooterOne from '../../common/footer/FooterOne';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withRouter from '../../utils/withRouterCustom';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import { startSession } from '../../utils/auth';
import { notifyUser } from '../../utils/notification-service';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const Login = (props) => {


    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {

        setLoading(true);
        props.login(values).then(async (resp) => {

            setLoading(false);
            console.log({ resp });
            if (resp && !resp.error) {
                startSession(resp);
                await props.setUserAction(resp.user);
                notifyUser('Login Successful', 'success', 5);
                let prevURL = localStorage.getItem('previousURL');
                console.log({ prevURL });
                if (prevURL) {
                    localStorage.removeItem('previousURL');
                    console.log({ prevURL });
                    props.router.navigate(prevURL);
                }
                else props.router.navigate('/');
            }

        }).catch((error) => {
            setLoading(false);
            notifyUser(error.message || 'Something went wrong', 'error', 5);
            console.log({ error });
        })

    };

    const googleLogin = (creds) => {
        console.log({ creds });

        let info = jwtDecode(creds.credential);

        console.log({ info })
        let args = {
            email: info.email,
            firstName: info.given_name,
            lastName: info.family_name,
            googlePicture: info.picture
        }
        loginViaGoogle(args);
    };

    const loginViaGoogle = (params) => {

        props.loginViaGoogle(params).then((resp) => {

            console.log({ resp });
            if (resp && !resp.error) {


                startSession(resp);
                notifyUser('Logged in successfully', 'success', 5);
                let prevURL = localStorage.getItem('previousURL');
                console.log({ prevURL });
                if (prevURL) {
                    localStorage.removeItem('previousURL');
                    console.log({ prevURL });
                    props.router.navigate(prevURL);
                }
                else props.router.navigate('/');

            }

        }).catch((error) => {
            setLoading(false);
            notifyUser(error.message || 'Something went wrong', 'error', 5);
            console.log({ error });
        })

    }
    return (
        <>
            <Spin spinning={loading}>
                <HeaderOne />
                <div className='container my-5'>
                    <div className='login-form'>
                        <h1 className='text-center mb-4'>Login</h1>
                        <Form
                            className='row'
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}                            
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}

                            autoComplete="off"
                        >

                        <Form.Item
                            label="Email"
                            name="email"
                            className='col-lg-6'
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a valid Email",
                                    type: 'email'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            className='col-lg-6'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <div className='col-6 text-start'>
                            <Link to='/enter-email'><span>Forgot Password?</span></Link>
                        </div>

                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            className='rememberContent col-6 text-end m-0'
                        >
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                            <Form.Item
                                className='w-100'
                            >
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className="separator">OR</div>
                        <div className='googleSignup'>
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    googleLogin(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </div>
                        <div className='or-sky my-5' style={{ justifyContent: 'center', display: 'flex' }}>
                            {/* <div className="separator">OR</div> */}
                            <Link to={process.env.PUBLIC_URL + `/signup`} >Click here if you don't have account</Link>
                        </div>
                    </div>
                </div>
                <FooterOne />
            </Spin>
        </>)
}
function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        Login
    )
);
