import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import config from '../../config';
import withRouter from '../../utils/withRouterCustom';
class TestimonialOne extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            testimonials: []
        }

    }

    componentDidMount() {

        const $ = window.$;

        setTimeout(()=>{
            if ($(".testimonial-one__carousel").length) {
                $(".testimonial-one__carousel").owlCarousel({
                    loop: true,
                    autoplay: false,
                    margin: 30,
                    nav: false,
                    dots: false,
                    smartSpeed: 500,
                    autoplayTimeout: 10000,
                    navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
                    responsive: {
                        0: {
                            items: 1
                        },
                        768: {
                            items: 2
                        },
                        992: {
                            items: 2
                        },
                        1200: {
                            items: 2
                        }
                    }
                });
            }
        },1000);

        this.props.getTestimonials().then((resp) => {

            if (resp && !resp.error) {
                this.setState({ testimonials: resp }, () => {
                    this.props.setLoading(false);
                });
            }
        }).catch((ex) => {
            console.log({ ex });
        })
    }
    render() {
        let { testimonials } = this.state;
        let { loading } = this.props;
        let publicUrl = process.env.PUBLIC_URL + '/'

        console.log({ loading })
        return (
            <>
                {/* Start Testimonial One */}
                <section className="testimonial-one bg-light">
                    <div className="testimonial-one__bg"></div>
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="subtitle">Client Testimonials</h2>
                            <h2 className="sec-title__title">What Our Clients Say</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="owl-carousel owl-theme thm-owl__carousel testimonial-one__carousel">





                                    {testimonials && testimonials.map(item =>

                                        <div className="testimonial-one__single">
                                            <div className="testimonial-one__single-quote-icon">
                                                <span className="fa fa-quote-left"></span>
                                            </div>
                                            <div className="testimonial-one__single-top">
                                                <div className="img-box">
                                                    {/* <img src={publicUrl + "assets/images/testimonial/testimonial-v1-img1.jpg"} alt="" /> */}
                                                    <img alt={item.person_name} src={config.backendURL + item?.file?.url} />
                                                </div>
                                                <div className="text-box">
                                                    <h2>{item.person_name}</h2>

                                                </div>
                                            </div>
                                            <div className="testimonial-one__single-text">
                                                <p>{item.content} </p>
                                            </div>
                                            <ul className="review-box">
                                                <li><span className="fa fa-star"></span></li>
                                                <li><span className="fa fa-star"></span></li>
                                                <li><span className="fa fa-star"></span></li>
                                                <li><span className="fa fa-star"></span></li>
                                                <li><span className="fa fa-star"></span></li>
                                            </ul>
                                        </div>
                                    )}






                                    {/* <div className="testimonial-one__single">
                                        <div className="testimonial-one__single-quote-icon">
                                            <span className="fa fa-quote-left"></span>
                                        </div>
                                        <div className="testimonial-one__single-top">
                                            <div className="img-box">
                                                <img src={publicUrl + "assets/images/testimonial/testimonial-v1-img2.jpg"} alt="" />
                                            </div>
                                            <div className="text-box">
                                                <h2>Rubel Robi</h2>
                                                <p>Obstetrics & Gynaecology</p>
                                            </div>
                                        </div>
                                        <div className="testimonial-one__single-text">
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                        </div>
                                        <ul className="review-box">
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                        </ul>
                                    </div>




                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__single-quote-icon">
                                            <span className="fa fa-quote-left"></span>
                                        </div>
                                        <div className="testimonial-one__single-top">
                                            <div className="img-box">
                                                <img src={publicUrl + "assets/images/testimonial/testimonial-v1-img2.jpg"} alt="" />
                                            </div>
                                            <div className="text-box">
                                                <h2>Rubel Robi</h2>
                                                <p>Nephrology</p>
                                            </div>
                                        </div>
                                        <div className="testimonial-one__single-text">
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                        </div>
                                        <ul className="review-box">
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                        </ul>
                                    </div>



                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__single-quote-icon">
                                            <span className="fa fa-quote-left"></span>
                                        </div>
                                        <div className="testimonial-one__single-top">
                                            <div className="img-box">
                                                <img src={publicUrl + "assets/images/testimonial/testimonial-v1-img2.jpg"} alt="" />
                                            </div>
                                            <div className="text-box">
                                                <h2>Rubel Robi</h2>
                                                <p>Nephrology</p>
                                            </div>
                                        </div>
                                        <div className="testimonial-one__single-text">
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                        </div>
                                        <ul className="review-box">
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                        </ul>
                                    </div>
 */}


                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                {/* End Testimonial One */}
            </>
        )
    }
}
function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        TestimonialOne
    )
);

