import React, { useState, useCallback } from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import FooterOne from '../../common/footer/FooterOne';
import { Step1Form } from './signupSteps/Step1Form';
import { Step2Form } from './signupSteps/Step2Form';
import withRouter from '../../utils/withRouterCustom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import { startSession } from '../../utils/auth';
import moment from 'moment';
import { Spin, message } from 'antd';
import { notifyUser } from '../../utils/notification-service';
// const onFinish = (values) => {
//   console.log('Success:', values);
// };
// const onFinishFailed = (errorInfo) => {
//   console.log('Failed:', errorInfo);
// };

const Signup = (props) => {
  const [data, setData] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const handlePrevStep = useCallback(
    (data) => {
      setData(data);
      setStep(step - 1);
    },
    [step]
  );

  const handleNextStep = (args) => {

    setData(args);

    console.log({ data });

    console.log({ args });

    setLoading(true);
    props.signup(args).then((resp) => {


      setLoading(false);
      console.log({ resp });
      if (resp && !resp.error) {

        notifyUser('OTP sent to email', 'info', 5);

        setStep(step + 1);


      }

    }).catch((error) => {
      setLoading(false);
      notifyUser(error.message || 'Something went wrong', 'error', 5);
      console.log({ error });
    })

  }



  const verifyOTP = () => {


    console.log({ data });
    let args = {
      email: data.email,
      otp: Number(otp)
    }
    setLoading(true);
    props.verifyOTP(args).then((resp) => {

      setLoading(false);
      console.log({ resp });
      if (resp && !resp.error) {

        notifyUser('Email Verified', 'info', 5);
        startSession(resp);
        let prevURL = localStorage.getItem('previousURL');
        console.log({ prevURL });
        if (prevURL) {
          localStorage.removeItem('previousURL');
          console.log({ prevURL });
          props.router.navigate(prevURL);
        }
        else props.router.navigate('/');

      }

    }).catch((error) => {
      setLoading(false);
      notifyUser(error.message || 'Something went wrong', 'error', 5);
      console.log({ error });
    })
  }


  const resendOTP = () => {


    console.log({ data });
    let args = {
      email: data.email
    }
    setLoading(true);
    props.resendOTP(args).then((resp) => {

      setLoading(false);
      console.log({ resp });
      if (resp && !resp.error) {

        notifyUser('OTP Resent Successfully', 'success', 5);
      }

    }).catch((error) => {
      setLoading(false);
      notifyUser(error.message || 'Something went wrong', 'error', 5);
      console.log({ error });
    })
  }


  const handleSubmit = useCallback((data) => {
    setData(data);
    console.log("Data", data);
  }, []);


  const loginViaGoogle = (params) => {

    props.loginViaGoogle(params).then((resp) => {

      console.log({ resp });
      if (resp && !resp.error) {


        startSession(resp);
        notifyUser('Logged in successfully', 'success', 5);
        props.router.navigate('/');

      }

    }).catch((error) => {
      setLoading(false);
      notifyUser(error.message || 'Something went wrong', 'error', 5);
      console.log({ error });
    })

  }
  return (
    <>
      <Spin spinning={loading}>
        <HeaderOne />
        <div className='container my-5'>
          <div className='login-form'>
            <h1 className='text-center mb-4'>Sign Up</h1>
            {step === 1 && <Step1Form data={data} onSuccess={handleNextStep} loginViaGoogle={loginViaGoogle} verifyOTP={verifyOTP} />}

            {step === 2 && (
              <Step2Form
                resendOTP={resendOTP}
                otp={otp}
                setOtp={setOtp}
                data={data}
                onSuccess={verifyOTP}
                onBack={handlePrevStep}
              />
            )}
          </div>
        </div>
        <FooterOne />
      </Spin>
    </>
  )
};
function mapStateToProps(state) {
  return {

  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...appActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Signup
  )
);
