import React from 'react';
import { Link } from 'react-router-dom';
import ImageMap from "image-map";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as appActions from '../../redux/actions/app-actions';
import withRouter from '../../utils/withRouterCustom';
import { Select, Spin } from 'antd';
import HeaderSearch from '../../common/header-search';
import config from '../../config';
import debounce from 'lodash/debounce'; // Import debounce function from lodash
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import image1 from '../../assets/images/pexels-fr3nks-305564.jpg';
import image2 from '../../assets/images/pexels-fr3nks-305568.jpg';
import image3 from '../../assets/images/pexels-shvetsa-3845981.jpg';
class HeroOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addAttribute: false,
            searching: false,
            gaunPalikas: [{
                id: config.defaultCityId,
                gaunpalika_name: config.defaultCityName
            }],
            doctors: [],
            categories: [],
            selectedGaunpalika: config.defaultCityId,
            selectedString: null
        };

        this.onUserStringInputDebounce = debounce(this.onUserStringInputDebounce, 700);
    }
    componentDidMount() {

        setTimeout(() => {
            this.setState({
                addAttribute: true,
            });
        }, 500)

        const $ = window.$;

        // if ($(".slider-bg-slide").length) {
        //     $(".slider-bg-slide").each(function () {
        //       var Self = $(this);
        //       var bgSlideOptions = Self.data("options");
        //       var bannerTwoSlides = Self.vegas(bgSlideOptions);
        //     });
        // }

        if ($(".tabs-box").length) {
            $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
                e.preventDefault();
                var target = $($(this).attr("data-tab"));

                if ($(target).is(":visible")) {
                    return false;
                } else {
                    target
                        .parents(".tabs-box")
                        .find(".tab-buttons")
                        .find(".tab-btn")
                        .removeClass("active-btn");
                    $(this).addClass("active-btn");
                    target
                        .parents(".tabs-box")
                        .find(".tabs-content")
                        .find(".tab")
                        .fadeOut(0);
                    target
                        .parents(".tabs-box")
                        .find(".tabs-content")
                        .find(".tab")
                        .removeClass("active-tab");
                    $(target).fadeIn(300);
                    $(target).addClass("active-tab");
                }
            });
        }

        $('select').niceSelect();


        ///// actual code


    }

    onUserInput = (val) => {

        if (val.length > 0) {
            this.setState({ searching: true })
            this.props.getGaunpalikas(val).then((resp) => {

                console.log({ resp })
                this.setState(
                    {
                        gaunPalikas: resp
                    }, () => {
                        this.setState({ searching: false });
                        this.props.setParentGaunpalikas(resp);
                    })

            }).catch((ex) => {
                console.log({ ex });
            })
        }
        else if (val == "" || !val) {

            this.setState({ gaunPalikas: [] });
            this.props.setParentGaunpalikas([]);
        }
    }

    onUserSelect = (e) => {
        this.setState({ selectedGaunpalika: e.value }, () => {
            this.props.setParentGaunpalika(e.value);
        })
    }

    onUserStringSelect = (e, option) => {
        console.log({ e })
        console.log({ option })
        this.setState({ selectedString: e.value }, () => {


            // load listing
            if (e.title == 'Doctor') {
                // redirect to doctor detail
                this.props.router.navigate('/listings-details-doc/' + e.value);
            }
            else if (e.title == 'Clinic') {
                // redirect to doctor detail
                this.props.router.navigate('/listings-details-clinic/' + e.value);
            }
            else {
                this.props.router.navigate('/listings?cityId=' + this.state.selectedGaunpalika + '&selectedString=' + this.state.selectedString, {
                    state: {
                        gaunPalikas: this.state.gaunPalikas,
                        doctors: this.state.doctors,
                        categories: this.state.categories
                    }
                }
                )
            }


        })
    }

    onUserStringInputDebounce = async (val) => {




        this.onUserStringInput(val);

    }

    onUserStringInput = async (val) => {

        this.setState({ enteredString: val });
        if (val.length > 0) {
            this.setState({ searching: true })

            await this.props.getSpecialities(val).then((resp) => {

                if (resp) {
                    this.setState({ categories: resp }, () => {
                        this.props.setParentCategories(resp);
                        this.props.getDoctorsClinicsByGaunPalika(this.state.selectedGaunpalika, val).then((resp) => {

                            console.log({ resp })
                            this.setState(
                                {
                                    doctors: resp
                                }, () => {
                                    this.setState({ searching: false })
                                })

                        }).catch((ex) => {
                            console.log({ ex });
                        })

                    });
                }
            })
                .catch((ex) => {
                    console.log({ ex })
                })



        }
        else if (val == "" || !val) {

            this.setState({ doctors: [], categories: [] })
            this.props.setParentCategories([]);
        }
    }
    render() {
        const { gaunPalikas, searching, selectedGaunpalika, doctors, categories } = this.state;
        console.log({ selectedGaunpalika })


        ImageMap('img[usemap]', 1500)
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>

                {/* Start Banner One */}
                <section className="banner-one">
                    <div className="banner-one__inners">

                        <div className="slider-bg-slide">
                            <AwesomeSlider

                            >
                                <div data-src={image1} />
                                <div data-src={image2} />
                                <div data-src={image3} />
                            </AwesomeSlider>

                        </div>

                        <div className='slider-bg-slide-overly'>
                            <div className="container">
                                <div className="banner-one__content text-center">
                                    <div className="banner-one__tab-boxs">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="banner-one__tab tabs-box">
                                                    <div className="tabs-content">

                                                        {/* Start Tab */}
                                                        <div className="tab active-tab" id="places">
                                                            <div className="banner-one__tab-content-item">
                                                                <div className="banner-one__tab-content-places">
                                                                    <div className="banner-one__tab-content-places-form">
                                                                        <HeaderSearch
                                                                            onUserInput={this.onUserInput}
                                                                            onUserStringInput={this.onUserStringInputDebounce}
                                                                            onUserStringSelect={this.onUserStringSelect}
                                                                            onUserSelect={this.onUserSelect}
                                                                            categories={categories}
                                                                            doctors={doctors}
                                                                            gaunPalikas={gaunPalikas}
                                                                            searching={searching}
                                                                            selectedString={this.state.selectedString}
                                                                            selectedGaunpalika={selectedGaunpalika}
                                                                            enteredString={this.state.enteredString}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* End Tab */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Banner One */}
            </>
        )
    }
}
function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        HeroOne
    )
);
