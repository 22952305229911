import React from 'react';
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { BsFillHospitalFill } from "react-icons/bs";
import { FaUserDoctor } from "react-icons/fa6";
import { FaRegCalendarCheck } from "react-icons/fa";
import withRouter from '../../utils/withRouterCustom';
import { connect } from 'react-redux';
import * as appActions from '../../redux/actions/app-actions';
import { bindActionCreators } from 'redux';
import config from '../../config';
import { MdVerifiedUser } from 'react-icons/md';
class FeatureOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specialities: [],
            clinics: [],
            otherInfo: {}
        }
    }

    componentDidMount() {
        const counters = document.querySelectorAll('.counter');

        counters.forEach(counter => {
            counter.Counter = 0;
            const targetValue = parseInt(counter.innerText);

            const animation = setInterval(function () {
                counter.Counter += targetValue / 400;

                if (counter.Counter >= targetValue) {
                    clearInterval(animation);
                    counter.Counter = targetValue;
                }

                counter.innerText = Math.ceil(counter.Counter);
            }, 10);
        });

        this.props.getStatesOfNepal().then((resp) => {

            if (resp && !resp.error) {
                this.setState({ specialities: resp });
            }
        }).catch((ex) => {
            console.log({ ex });
        })

        this.props.getClinics().then((resp) => {

            if (resp && !resp.error) {
                // if (resp.length == 1) {
                //     resp.push(resp[0]);
                //     resp.push(resp[0]);
                //     resp.push(resp[0]);
                //     resp.push(resp[0]);
                // }
                this.setState({ clinicsList: resp });
            }
        }).catch((ex) => {
            console.log({ ex });
        })

        this.props.getOtherInfo(1).then((resp) => {

            if (resp && !resp.error) {
                this.setState({ otherInfo: JSON.parse(resp.configData) });
            }
        }).catch((ex) => {
            console.log({ ex });
        })
    }
    render() {
        const { specialities, clinics, otherInfo, doctorsList, clinicsList } = this.state;
        const settings1 = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 567,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                }
            ]
        };
        return (
            <>
                <section className="features-one">
                    <div className="container">
                        {/* <div className="sec-title text-center">
                            <h2 className="sec-title__title">Best Wedding Vendors</h2>
                            <p className="sec-title__text">Excepteur s occaecat cupidatat proident sunt</p>
                        </div> */}
                        <div className="row">
                            <Slider {...settings1}>
                                {/* Start Features One Single */}
                                {specialities && specialities.map(item =>
                                    <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                        <div className='h-100' style={{ cursor: 'pointer' }} onClick={() => {


                                            // this.props.router.navigate('/listings?selectedString=' + item.name + '&selectedStringId=' + item.id)
                                            this.props.router.navigate("listings?stateId=" + item.id)


                                        }} >
                                            <div className="features-one__single text-center">
                                                <div className="features-one__single-icon">
                                                    <img src={config.backendURL + item.image} width={'100px'} />
                                                </div>
                                                <div className="features-one__single-title">
                                                    <h2 >{item.name}</h2>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* End Features One Single */}

                                {/* Start Features One Single
                                <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
                                    <div className="features-one__single text-center">
                                        <div className="features-one__single-icon">
                                            <span className="icon-employee"></span>
                                        </div>
                                        <div className="features-one__single-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/`}>Dentistry</Link></h2>
                                        </div>
                                    </div>
                                </div>
                                {/* End Features One Single */}

                            </Slider>
                        </div>
                    </div>
                </section>

                {/* <section className='bg-secondary'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 text-white">
                                <span className="subtitle left">About Us</span>                                
                                <h1 className="text-white">{otherInfo.heading1}</h1>
                                <p>{otherInfo.description1}</p>
                                <div className='d-flex gap-3 mt-4'>
                                    <BsFillHospitalFill size={70} />
                                    <div className='content'>
                                        <h5 className="text-white">{otherInfo.subheading1}</h5>
                                        <p>{otherInfo.subdescription1}</p>
                                    </div>
                                </div>

                                <div className='d-flex gap-3 mt-4'>
                                    <FaUserDoctor size={70} />
                                    <div className='content'>
                                        <h5 className="text-white">{otherInfo.subheading2}</h5>
                                        <p>{otherInfo.subdescription2}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{ position: 'relative' }}>
                                <div className='sky-img1'>
                                    <img src={otherInfo.image1 && otherInfo.image1[0] && otherInfo.image1[0].url ? (config.APIURL1 + '/' + (otherInfo && otherInfo.image1 && otherInfo.image1[0] && otherInfo.image1[0].url)) : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt="img1" style={{
                                        // width: '451px',
                                        height: '260px',
                                        objectFit: 'cover'
                                    }} />
                                </div>
                                <div className='sky-img2'>
                                    <img src={otherInfo.image2 && otherInfo.image2[0] && otherInfo.image2[0].url ? (config.APIURL1 + '/' + (otherInfo && otherInfo.image2 && otherInfo.image2[0] && otherInfo.image2[0].url)) : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt="img2" style={{
                                        // width: '382px',
                                        height: '260px',
                                        objectFit: 'cover'
                                    }} />
                                </div>
                            </div>
                        </div>

                        <div className='sky-counter text-white d-flex gap-3 justify-content-evenly'>
                            {otherInfo.count1 && <div className='count text-center'>
                                <span><b className="counter">{otherInfo.count1}</b><span className="plus">+</span></span>
                                <h4 className="text-white">{otherInfo.count1Title}</h4>
                            </div>
                            }

                            {otherInfo.count2 && <div className='count text-center'>
                                <span><b className="counter">{otherInfo.count2}</b><span className="plus">+</span></span>
                                <h4 className="text-white">{otherInfo.count2Title}</h4>
                            </div>}

                            {otherInfo.count3 && <div className='count text-center'>
                                <span><b className="counter">{otherInfo.count3}</b><span className="plus">+</span></span>
                                <h4 className="text-white">{otherInfo.count3Title}</h4>
                            </div>}

                            {otherInfo.count4 && <div className='count text-center'>
                                <span><b className="counter">{otherInfo.count4}</b><span className="plus">+</span></span>
                                <h4 className="text-white">{otherInfo.count4Title}</h4>
                            </div>}

                        </div>
                    </div>
                </section> */}

                {/* <section className='how-work'>
                    <div className='container' style={{
                        padding: '62px 40px',
                        backgroundColor: '#f5f5f5',
                        borderTopRightRadius: '30px',
                        borderTopLeftRadius: '30px'
                    }}>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <span className="subtitle">Work Process</span>
                                <div className="section-title mb-4">                                    
                                    <h1>{otherInfo.processTitle}</h1>
                                </div>
                            </div>
                          
                        </div>

                        <div className='row mt-5'>
                            <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-5'>
                                <div className='works-block'>
                                    <BsFillHospitalFill size={40} />
                                    <h5>{otherInfo.step1Title}</h5>
                                    <p>{otherInfo.step1Description}</p>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-5'>
                                <div className='works-block'>
                                    <FaRegCalendarCheck size={40} />
                                    <h5>{otherInfo.step2Title}</h5>
                                    <p>{otherInfo.step2Description}</p>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-5'>
                                <div className='works-block'>
                                    <FaUserDoctor size={40} />
                                    <h5>{otherInfo.step3Title}</h5>
                                    <p>{otherInfo.step3Description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                <section className='sky-slider'>
                    <div className="sec-title text-center">
                        
                        <h2 className="sec-title__title">Top Rated Clinics</h2>
                    </div>
                    <div>
                        <Slider {...settings}>
                            {clinics && clinics.map(item =>
                                <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                    <div className='slideImage'>
                                        <Link to={'/listings-details-clinic/' + item.id}>
                                            <img src={config.backendURL + item.image?.url} width={'300px'} />
                                        </Link>
                                    </div>
                                )}
                            </Slider>
                        </div>
                    </div>
                </section> */}

                <section className='dental-section'>
                    <div className='container'>
                        <span className="subtitle">WHAT WE DO FOR OUR PATIENTS</span>
                        <h3 className='sec-title__title text-center mb-5'>Dental Services</h3>
                        <Row gutter={[35, 24]} justify={'center'}>
                            <Col md={12} lg={11} xl={10}>
                                <div className='dental-blocks h-100 d-flex flex-column'>                            
                                    <img src='assets/images/dental/dentist-doc.jpg' alt='dentist' />
                                    <div className='content-sec d-flex' onClick={async()=>{
                                                  await this.props.getSpecialities('Dentists').then((resp) => {

                                                    if (resp) {
                                                        this.setState({ categories: resp }, () => {
                                                          
                                                            this.props.getDoctorsClinicsByGaunPalika(this.state.selectedGaunpalika, 'Dentists').then((resp) => {
                                    
                                                                console.log({ resp })
                                                                this.setState(
                                                                    {
                                                                        doctors: resp
                                                                    }, () => {
                                                                        this.setState({ searching: false })
                                                                        this.props.router.navigate('/listings?cityId=' + this.props.parentGaunpalika + '&selectedString=' + '10050018', {
                                                                            state: {
                                                                                gaunPalikas: this.props.parentGaunpalikas,
                                                                                doctors: this.state.doctors,
                                                                                categories:this.state.categories
                                                                            }
                                                        })
                                                                    })
                                    
                                                            }).catch((ex) => {
                                                                console.log({ ex });
                                                            })
                                    
                                                        });
                                                    }
                                                })
                                                    .catch((ex) => {
                                                        console.log({ ex })
                                                    })
                                                    
                                                    
                                    }}>
                                        <img src='assets/images/icon/arrow-right.svg' alt="arrow" />
                                        <div class="content px-3 py-2">
                                            <h2>Dentists</h2>
                                            <p>Teething troubles? Schedule a dental checkup</p>    
                                        </div>
                                    </div>                            
                                </div>
                            </Col>
                            <Col md={12} lg={11} xl={10}>
                                <div className='dental-blocks h-100 d-flex flex-column'>                            
                                    <img src='assets/images/dental/dental-clinic.jpg' alt='dental' />
                                    <div className='content-sec d-flex'
                                    onClick={async()=>{
                                        await this.props.getSpecialities('Dental').then((resp) => {

                                          if (resp) {
                                              this.setState({ categories: resp }, () => {
                                                
                                                  this.props.getDoctorsClinicsByGaunPalika(this.state.selectedGaunpalika, 'Dental').then((resp) => {
                          
                                                      console.log({ resp })
                                                      this.setState(
                                                          {
                                                              doctors: resp
                                                          }, () => {
                                                              this.setState({ searching: false })
                                                              this.props.router.navigate('/listings?cityId=' + this.props.parentGaunpalika + '&selectedString=' + '10050019', {
                                                                  state: {
                                                                      gaunPalikas: this.props.parentGaunpalikas,
                                                                      doctors: this.state.doctors,
                                                                      categories:this.state.categories
                                                                  }
                                              })
                                                          })
                          
                                                  }).catch((ex) => {
                                                      console.log({ ex });
                                                  })
                          
                                              });
                                          }
                                      })
                                          .catch((ex) => {
                                              console.log({ ex })
                                          })
                                          
                                          
                          }}
                                    >
                                        <img src='assets/images/icon/arrow-right.svg' alt="arrow" />
                                        <div class="content content px-3 py-2">
                                            <h2>Dental Check-Up </h2>
                                            <p>Keep your teeth in top shape!</p>    
                                        </div>
                                    </div>                            
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>




                <section className="listings_one_wrap overflow-visible pt-5">
                    <div className="container-full-width clearfix pt-3">
                        <div className="listings__one__content container pt-0 px-2 bg-transparent">
                            {clinicsList && clinicsList.length > 0 &&
                                <section className="listings_three-page one">
                                     <div className="row justify-content-between gap-3 align-items-center">
                                        <div className='col'>
                                            <span className="subtitle left">Caring for You</span>
                                            <h3 className='sec-title__title text-left'>Clinics</h3>
                                        </div>
                                        {/* <div className='col text-end'>
                                            <Link className="btn btn-info sky-btn">View More Clinics</Link>
                                        </div> */}
                                    </div>
                                    
                                    <div className="row mt-5">
                                        {/* Start Place One Single Clinics */}
                                        {clinicsList && clinicsList.filter(x => x.status).map(item =>

                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="place-one__single">
                                                    <img alt={item.firstName} src={config.backendURL + item?.image?.url} />
                                                    <div className='content p-3 w-100'>
                                                        <div className='d-md-flex justify-content-between align-items-end'>
                                                            <div>
                                                            <h2>{item.firstName} {item.lastName}</h2>
                                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>{item.specialities.map((x, idx) => <><span>{x.name}</span><span style={{ display: idx >= item.specialities.length - 1 ? 'none' : '' }}>,</span></>)}</div>
                                                            <p>{item.experience} Years Experience Overall</p>
                                                            </div>
                                                            <Link to={`${process.env.PUBLIC_URL}/listings-details-clinic/` + item.id} 
                                                            className="btn btn-info sky-btn my-2">Book Clinic Visit</Link>
                                                        </div>
                                                        {/* <p>BDS, MDS - Conservative Dentistry & Endodontics</p> */}
                                                       
                                                        <hr />
                                                        <p><MdVerifiedUser /> Medical Registration Verified</p>
                                                        {/* <p><FaRegThumbsUp /> 99% (4321 patients)</p> */}
                                                        {/* <p>Dr. Rashmi Shetty having completed her graduation (BDS) in the esteemed JSS Dental College and Research Center, Mysore in the year 2004. </p> */}
                                                        {/* <Link to={`${process.env.PUBLIC_URL}/listings-details-clinic/` + item.id} className="btn btn-info sky-btn my-4">Book Clinic Visit</Link> */}
                                                    </div>                                                      
                                                </div>
                                            </div>

                                        )}

                                        {/* End Place One Single Doctors*/}


                                    </div>
                                </section>
                            }

                        </div>
                    </div>
                </section>



            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        FeatureOne
    )
);

